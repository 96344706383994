import Vue from 'vue';

import {
  StoreState,
  ViewTree,
  OrgQuery,
  receiveSearchParams,
  receiveSuggestParams,
  SearchResult,
  RelatedKeywords,
} from './store.entities';

import {
  FacetDrillDownInfo,
  HighlightPart,
  RelatedKeyword,
} from '../openapi-clients/finder_service';

const nop = () => {
  // do nothing.
};

/**
 * ページログ記録を停止する。
 */
function stopPagelog() {
  (window as any).MF_track_ext?.stop();
}

const mutations = {
  /**
   * state.dataMap に searchUrl に対応する入れ物が無ければ作る。
   * @param  {String} searchUrl ～/x_search.x
   */
  prepDataMap(state: StoreState, { searchUrl }: { searchUrl: string }): void {
    if (state.dataMap[searchUrl]) return;
    Vue.set(state.dataMap, searchUrl, {
      orgQuery: {},
      query: {},
      searchResult: {},
      searchResultParams: {},
      suggestResult: {},
      keywordRanking: {
        result: [],
        use: false,
      },
      relatedKeywords: {
        enabled: false,
        lastReq: {
          max: null,
          q: '',
        },
        result: [],
      } as RelatedKeywords,
      imgsize: null,
      viewTime: 0,
    });
  },

  /**
   * state.searchUrl を更新する。～/x_search.x
   */
  setSearchUrl(state: StoreState, url: string): void {
    state.searchUrl = url;
  },

  /**
   * state と state.DataMap[searchUrl] の query と orgQuery を更新する。
   * @param {Object} query     検索リクエストに使われたクエリパラメータ
   * @param {Object} orgQuery  fetchSearchDataByQuery で渡されたクエリパラメータ
   * @param {String} searchUrl ～/x_search.x
   */
  setQuery(
    state: StoreState,
    {
      query,
      orgQuery,
      searchUrl,
    }: {
      query: OrgQuery;
      orgQuery: OrgQuery;
      searchUrl: string;
    },
  ): void {
    state.query = query;
    state.dataMap[searchUrl].query = query;
    state.orgQuery = orgQuery;
    state.dataMap[searchUrl].orgQuery = orgQuery;
  },

  /**
   * 最後の検索リクエストをキャンセルする。
   */
  searchCancel(state: StoreState): void {
    if (state.searchCancelFunc != nop) {
      state.searchCancelFunc();
      state.searchCancelFunc = nop;
    }
  },

  /**
   * state と state.DataMap[searchUrl] の imgsize を更新する。
   * @param {Number or String} imgsize   0, 1, 2, 3 のいずれか
   * @param {String} searchUrl  ～/x_search.x
   */
  setImgsize(
    state: StoreState,
    { imgsize, searchUrl }: { imgsize: string; searchUrl: string },
  ): void {
    state.imgsize = imgsize;
    state.dataMap[searchUrl].imgsize = imgsize;
  },

  /**
   * htmlエレメントのlang属性を設定する。
   * @param {String} lang  [description]
   */
  setHtmlLang(state: StoreState, lang: string): void {
    document.getElementsByTagName('html')[0].lang = lang;
  },

  /**
   * サジェストリクエストを実行中であることを示すために、
   * state.suggestCancelFunc を設定する。
   * 既に設定されている場合は、実行中のリクエストをキャンセルするために、まず state.suggestCancelFunc() を実行する。
   * @param  {Function} cancelFunc リクエストキャンセル時に呼び出すための関数
   */
  loadingSuggest(
    state: StoreState,
    { cancelFunc }: { cancelFunc: () => void },
  ): void {
    if (state.suggestCancelFunc != nop) {
      state.suggestCancelFunc();
      state.suggestCancelFunc = nop;
    }
    state.suggestCancelFunc = cancelFunc;
  },

  /**
   * 検索リクエストを実行中であることを示すために、
   * state.searchCancelFunc を設定する。
   * 既に設定されている場合は、実行中のリクエストをキャンセルするために、まず state.searchCancelFunc() を実行する。
   * また、state.searchResult.organic と、state.dataMap[searchUrl].searchResult.organic に null を設定する。
   * bodyエレメントのclassに mf_finder_loading_search_result を追加する。
   * @param  {Function} cancelFunc リクエストキャンセル時に呼び出すための関数
   * @param {String} searchUrl  ～/x_search.x
   */
  loadingSearch(
    state: StoreState,
    { searchUrl, cancelFunc }: { searchUrl: string; cancelFunc: () => void },
  ): void {
    // 検索リクエスト中のクラス設定
    try {
      document.body.classList.add('mf_finder_loading_search_result');
    } catch (e) {
      console.error(e);
    }
    state.searchResult.organic = null;
    state.dataMap[searchUrl].searchResult.organic = null;
    state.searchResult.featured_contents = null;
    state.searchResult.dd = null;
    state.searchResult.category = null;

    if (state.searchCancelFunc != nop) {
      state.searchCancelFunc();
      state.searchCancelFunc = nop;
    }
    state.searchCancelFunc = cancelFunc;
  },

  /**
   * state.relatedKeywords.use と、state.dataMap[searchUrl].relatedKeywords.use に true を設定する。
   * @param {String} searchUrl  ～/x_search.x
   */
  useRelatedKeywords(
    state: StoreState,
    { searchUrl }: { searchUrl: string },
  ): void {
    state.relatedKeywords.enabled = true;
    state.dataMap[searchUrl].relatedKeywords.enabled = true;
  },

  /**
   * 関連キーワードリクエストのパラメータを保存する。
   * state.relatedKeywords.lastReq と、state.dataMap[searchUrl].relatedKeywords.lastReq を更新する
   */
  saveRelatedKeywordsParams(
    state: StoreState,
    {
      req, // 使ったクエリパラメータ
      searchUrl,
      q,
      prefix,
    }: {
      req: { max: number; q: string };
      searchUrl: string;
      q: string;
      prefix: string;
    },
  ): void {
    state.relatedKeywords.lastReq = req;
    state.relatedKeywords.q = q;
    state.relatedKeywords.prefix = prefix;

    state.dataMap[searchUrl].relatedKeywords.lastReq = req;
    state.dataMap[searchUrl].relatedKeywords.q = q;
    state.dataMap[searchUrl].relatedKeywords.prefix = prefix;
  },

  /**
   * 関連キーワードリクエストのレスポンスを保存する。
   * state.relatedKeywords.result と、state.dataMap[searchUrl].relatedKeywords.result を更新する。
   * ただし state.dataMap[searchUrl].relatedKeywords.lastReq != req の場合は何もしない。
   * @param  {Function} cancelFunc 未使用
   * @param  {Object} err        未使用
   * @param  {Object} dat        未使用
   * @param  {Object} req        使ったクエリパラメータ
   * @param  {String} searchUrl  ～/x_search.x
   */
  setRelatedKeywords(
    state: StoreState,
    {
      relatedKeywords,
      req,
      searchUrl,
    }: {
      relatedKeywords: RelatedKeyword[];
      req: { max: number; q: string };
      searchUrl: string;
    },
  ): void {
    if (state.dataMap[searchUrl].relatedKeywords.lastReq !== req) return;
    state.relatedKeywords.result = relatedKeywords;
    state.dataMap[searchUrl].relatedKeywords.result = relatedKeywords;
  },

  /**
   * state.keywordRanking.use と、state.dataMap[searchUrl].keywordRanking.use に true を設定する。
   * @param  {String} searchUrl  ～/x_search.x
   */
  useKeywordRanking(
    state: StoreState,
    { searchUrl }: { searchUrl: string },
  ): void {
    state.keywordRanking.use = true;
    state.dataMap[searchUrl].keywordRanking.use = true;
  },

  /**
   * state.keywordRanking.url と、state.dataMap[searchUrl].keywordRanking.url を設定する。
   * @param  {String} url  キーワードランキングのリクエストURL
   * @param  {String} searchUrl  ～/x_search.x
   */
  prepareKeywordRanking(
    state: StoreState,
    { url, searchUrl }: { url: string; searchUrl: string },
  ): void {
    state.keywordRanking.url = url;
    state.dataMap[searchUrl].keywordRanking.url = url;
  },

  /**
   * state.keywordRanking.result と、state.dataMap[searchUrl].keywordRanking.result を更新する。
   * ただし、state.keywordRanking.url が url と一致しない場合は、state.keywordRanking.result を更新しない。
   * @param  {Object} dat  キーワードランキングのレスポンスデータ
   * @param  {String} url  キーワードランキングのリクエストURL
   * @param  {String} searchUrl  ～/x_search.x
   */
  receiveKeywordRanking(
    state: StoreState,
    { dat, url, searchUrl }: { dat: string[]; url: string; searchUrl: string },
  ): void {
    if (state.keywordRanking.url === url) {
      state.keywordRanking.result = dat;
    }
    state.dataMap[searchUrl].keywordRanking.result = dat;
  },

  /**
   * state.suggestCancelFunc が cancelFunc に一致する場合は、state.suggestCancelFunc を空にして以下実行。
   * state.suggestResult と、state.dataMap[searchUrl].suggestResult にキーワードランキングのデータを保存し、
   * @param  {Function} cancelFunc リクエストキャンセル時に呼び出すための関数
   * @param  {Object} err  エラーオブジェクト
   * @param  {Object} dat  キーワードランキングのレスポンスデータ
   * @param  {Object} req  サジェストリクエストに使ったクエリパラメータ
   * @param  {String} searchUrl  ～/x_search.x
   */
  receiveSuggest(
    state: StoreState,
    { cancelFunc, dat, req, searchUrl }: receiveSuggestParams,
  ): void {
    // console.log('receiveSuggest')
    if (state.suggestCancelFunc !== cancelFunc) return;

    // console.log('sug:' + dat)
    state.suggestCancelFunc = nop;
    // console.log(dat)
    state.suggestResult = { dat, req };
    state.dataMap[searchUrl].suggestResult = { dat, req };
  },

  /**
   * state.dataMap[searchUrl].searchResultParams を設定する。
   * @param  {String} searchUrl  ～/x_search.x
   * @param {Object} query     検索リクエストに使われたクエリパラメータ
   */
  setSearchResultParams(
    state: StoreState,
    { query, searchUrl }: { query: OrgQuery; searchUrl: string },
  ): void {
    state.dataMap[searchUrl].searchResultParams = query;
  },

  /**
   * state.searchCancelFunc が cancelFunc に一致する場合は state.searchCancelFunc を空にして以下実行。
   * bodyエレメントのclassから mf_finder_loading_search_result を削除する。
   * state と state.dataMap[searchUrl] の searchResult, searchResultParams, imgsize を
   * 検索のレスポンスデータに基づいて設定し、suggestResultParams, suggestResult を空にし、
   * viewTime に現在のタイムスタンプを設定し、必要に応じてページログ記録を開始する。
   * ｑパラメータが空か否かに応じて bodyエレメントのclassに mf_finder_noquery を設定する。
   * @param  {Function} cancelFunc リクエストキャンセル時に呼び出すための関数
   * @param  {Object} dat  検索リクエストのレスポンスデータ
   * @param  {String} searchUrl  ～/x_search.x
   */
  setSearchResult(
    state: StoreState,
    {
      cancelFunc,
      dat,
      query,
      searchUrl,
    }: receiveSearchParams & { query: OrgQuery },
  ): void {
    if (state.searchCancelFunc !== cancelFunc) return;
    // console.log('setSearchResult')

    // 検索リクエスト中のクラス解除
    try {
      document.body.classList.remove('mf_finder_loading_search_result');
    } catch (e) {
      console.error(e);
    }

    state.searchCancelFunc = nop;

    // TODO: レスポンスではなく queryをそのまま設定してよいか確認
    // console.log({ query, dat });
    const params = query;

    const convHighlight = (
      parts: Array<HighlightPart>,
    ): { id: number; text: string }[] =>
      parts &&
      parts.map((part) => ({
        id: part.is_highlighted ? 1 : 0,
        text: part.text,
      }));

    state.isDrilldownItemSelected = false;
    const facetDrilldown2ddItem = (src: FacetDrillDownInfo): ViewTree => {
      if (src.is_selected) {
        state.isDrilldownItemSelected = true;
      }
      return {
        depth: src.depth,
        title: src.title,
        path: src.path,
        count: src.count,
        selected: !!src.is_selected,
        opened: !!src.is_opened,
        child: src.children?.map && src.children.map(facetDrilldown2ddItem),
      };
    };

    // 新形式を旧形式へ変換
    const searchResult: SearchResult = dat && {
      params,
      doctype: {
        current: params?.doctype,
      },
      organic: dat.organic && {
        hits: dat.organic.hits,
        range: [dat.organic.range_min, dat.organic.range_max],
        docs: dat.organic.docs?.map((doc) => ({
          count: doc.ranking,
          uri: doc.url,
          thumbnail_url: doc.thumbnail_url,
          title: doc.title,
          description: doc.description,
          startText: doc.start_text,
          doctype: doc.doctype,
          imgURL: {
            imgSizes: [96, 120, 200, 320],
            map: {},
          },
          highlight: {
            uri: convHighlight(doc.highlight.url_parts),
            title: convHighlight(doc.highlight.title_parts),
            description: convHighlight(doc.highlight.description_parts),
            documentText: convHighlight(doc.highlight.document_text_parts),
          },
          attrs: {},
        })),
      },
      category: {
        items: dat.category?.items as string[],
        selected: dat.category?.selected as string[],
      },
      dd: {
        noselect: [
          dat.parameter?.drilldown.length === undefined ||
            dat.parameter?.drilldown.length === 0,
        ],
        view_trees: [
          dat.facet_drilldown_roots?.[0]?.facet_drilldowns.map(
            facetDrilldown2ddItem,
          ),
        ],
      },
      spellcheck: dat.spellcheck, // MF3 finder API
      featured_contents: dat.featured_contents,
    };
    state.searchResult = searchResult;
    state.dataMap[searchUrl].searchResult = searchResult;

    state.searchResultParams = params;
    state.dataMap[searchUrl].searchResultParams = params;

    state.suggestCancelFunc = nop;

    state.suggestResult = {};
    state.dataMap[searchUrl].suggestResult = {};

    if (Object.keys(dat).length === 0) {
      if (!state.disablePagelog) {
        stopPagelog();
      }
    } else {
      // 検索結果内のimgsizeは無視する?
      state.imgsize = query.imgsize;
      state.dataMap[searchUrl].imgsize = query.imgsize;

      const date = +new Date();
      state.viewTime = date;
      state.dataMap[searchUrl].viewTime = date;

      // TODO: logid が無い
      // if (!state.disablePagelog) {
      //   startPagelog({
      //     searchBaseurl: searchUrl.replace(/[^\\/]+$/, ''),
      //     logid: dat.track.l,
      //   });
      // }
      try {
        if (params.q) {
          document.body.classList.remove('mf_finder_noquery');
        } else {
          document.body.classList.add('mf_finder_noquery');
        }
      } catch (e) {
        console.error(e);
      }
    }
  },

  /**
   * state.disablePagelog に true を設定する。
   */
  disablePagelog(state: StoreState): void {
    state.disablePagelog = true;
  },

  /**
   * state.searchResultsMounted に true を設定する。
   */
  searchResultsMounted(state: StoreState): void {
    state.searchResultsMounted = true;
  },

  /**
   * state.mfHelper を設定する。
   * @param {String} v     mfHelper に設定する値
   */
  setMfHelper(state: StoreState, v: string): void {
    // console.log('setMfHelper:' + v)
    state.mfHelper = v;
  },
};

export { mutations };
